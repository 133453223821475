@import 'prismjs';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

body {
  width: 100%!important;
  background-color: #f4f6f8;
}

a:not([class]):not([href=""]) {
  color: #00afff;
}

a {
  text-decoration: none;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}
:root {
  --text-color: #defaultColor;
}
.MuiTabScrollButton-root {
  svg {
    path {
      color: var(--text-color);
    }
  }
}

.Mui-disabled {
  cursor: no-drop!important;
  pointerEvents: unset;
}
